import { Base64 } from 'js-base64'

const Order = class {
  constructor(order) {
    this.id = order.id
    this.orderNum = typeof order.order_num !== 'undefined' ? order.order_num : ''
    this.delivery = order.delivery
    this.products = (order.products && order.products.length > 0 ? order.products : order.products.data)
    this.comment = order.comment
    this.summary = order.summary
    this.deliverySummary = order.deliverySummary
    this.client = order.client
    this.created_at = order.created_at
    this.default_payment = 0
    this.edit = !!(order && order.edit)
  }

  setDeliveryType(val) {
    this.delivery_type = val
  }

  setDeliveryId(deliveries) {
    if (deliveries) {
      const delivery = deliveries.find(
        d => d.slug === this.delivery.delivery_method
      )

      // console.log('this.delivery', this.delivery)

      if (typeof delivery !== 'undefined') {
        this.delivery.delivery_id = delivery.id
        this.default_payment = delivery.default_payment
      }
    }
  }

  setProductQuantity(qty, productId) {
    // console.log('setProductQuantity', qty, productId);
    const product = this.products.find(p => p.uuid === productId)
    if (product) product.quantity = qty

    console.log('setProductQuantity 2', product);
    return this
  }

  setClientProductPrice(price, productId, characteristicId) {
    const product = this.products.find(p => p.uuid === productId)
    if (!product.is_order_edit && (product.characteristics || []).length > 0) {
      const characteristic = product.characteristics.find(
        c => c.id === characteristicId
      )
      if (typeof characteristic !== 'undefined') {
        characteristic.price = price / product.quantity
      }
    } else {
      product.price = price / product.quantity
    }
    if (this.products) {
      this.products.forEach(element => {
        if (element.uuid === productId) {
          element.total_price = price
        }
      })
    }
    return this
  }

  setPaymentMethod(e) {
    this.deliverySummary.paymentMethod = e
    // console.log(this.deliverySummary)
  }

  getTotalPrice() {
    // const dCost = (this.deliverySummary.delivery_data) ? Number.parseFloat(this.deliverySummary.delivery_data.delivery_cost) : 0
    const dCost = Number.parseInt(this.summary.delivery_cost)
    const deposit =
      typeof this.deliverySummary.deposit !== 'undefined'
        ? Number.parseInt(this.deliverySummary.deposit)
        : 0
    const prices = {
      delivery_cost: dCost || 0,
      total: 0,
      total_drop: 0,
      deposit_total: 0
    }
    /*
    this.products.forEach(p => {
      prices.total += Number.parseFloat(p.total_price)
      prices.total_drop += Number.parseFloat(p.total_price_drop)
    })
    */
    const pr = this.updateProductPrice()
    prices.total = pr.total
    prices.total_drop = pr.total_drop
    // minus deposit
    const depositPaymentStatus =
      typeof this.deliverySummary.depositPaymentStatus !== 'undefined'
        ? this.deliverySummary.depositPaymentStatus
        : null
    if (this.deliverySummary.depositPaymentType === '') {
      prices.deposit_total = prices.total
    } else {
      if (depositPaymentStatus === 'paid') {
        prices.deposit_total = prices.total
      } else if (depositPaymentStatus === 'unpaid') {
        prices.deposit_total = prices.total
      } else {
        prices.deposit_total = prices.total
      }
    }
    prices.deposit_total = prices.deposit_total
      ? prices.deposit_total - deposit
      : 0

    if (deposit > 0) {
      prices.deposit_total = prices.total - deposit || 0
    } else {
      prices.deposit_total = prices.total || 0
    }
    this.summary = prices
    // console.log(prices)
    return prices
  }

  getDeliveryMethodId() {
    return this.delivery.delivery_id
  }

  getPaymentMethodId() {
    return this.deliverySummary.paymentMethod
  }

  getDeliveryMethod() {
    return this.delivery.delivery_method
  }

  updateOrderStatuses(statuses) {
    if (statuses) {
      const role = localStorage.getItem('role');
      if (role == 'manager' || role == 'dropshipper') {
        statuses.forEach(s => {
          if (s.name == 'Новий' || s.name == 'Відміна' || s.name == 'Робити пізніше') {
            s.visible = true
            return
          } else {
            s.visible = false
          }
        })
      } else if (role == 'admin') {
        statuses.forEach(s => {
          s.visible = true
          return
        })
      }

      // const deliveryMethodId = this.getDeliveryMethodId();
      // statuses.forEach(s => {
        // if ((s.show_for || []).length > 0) {
        //   const i = s.show_for.find(i => i === deliveryMethodId)
        //   if (typeof i !== 'undefined') {
        //     s.visible = true
        //     return
        //   }
        // }
        // s.visible = false
      // })
      // set default status to order
      const selected = statuses.find(s => s.selected)

      if (selected && selected.id) {
        this.deliverySummary.orderStatus = selected.id
      }
      return statuses.filter(s => s.visible)
    }
  }

  updatePaymentStatuses(statuses) {
    if (statuses) {
      const deliveryMethodId = this.getDeliveryMethodId()
      statuses.forEach(s => {
        if ((s.show_for || []).length > 0) {
          const i = s.show_for.find(i => i === deliveryMethodId)
          if (typeof i !== 'undefined') {
            s.visible = true
            return
          }
        }
        s.visible = false
      })
      // set default status to order
      const selected = statuses.find(s => s.selected)
      this.deliverySummary.paymentStatus = selected.id
      return statuses.filter(s => s.visible)
    }
  }

  paymentsToDelivery(payments) {
    console.log('payments', payments)
    if (payments) {
      const deliveryMethodId = this.getDeliveryMethodId()

      // console.log("deliveryMethodId", deliveryMethodId)
      payments.forEach(s => {
        if ((s.show_for || []).length > 0) {
          const i = s.show_for.find(i => i === deliveryMethodId)
          if (typeof i !== 'undefined') {
            s.visible = true
            return
          }
        }
        s.visible = false
      })

      // const selected = payments.find(s => s.selected)
      this.deliverySummary.paymentMethod = this.default_payment
      // this.paymentsToPayments(payments)

      return payments.filter(s => s.visible)
    }
  }

  paymentsToPayments(statuses) {
    if (statuses) {
      const paymentMethodId = this.getPaymentMethodId()
      statuses.forEach(s => {
        if ((s.show_for_payments || []).length > 0) {
          const i = s.show_for_payments.find(i => i === paymentMethodId)
          if (typeof i !== 'undefined') {
            s.visible_payment = true
            return
          }
        }
        s.visible_payment = false
        // s.selected = false;
      })
      const selected = statuses.find(s => s.selected)
      if (selected && selected.id && this.deliverySummary.paymentStatus === '') {
        this.deliverySummary.paymentStatus = selected.id
      }
      return statuses.filter(s => s.visible_payment)
    }
  }

  updateProductPrice() {
    const prices = { total: 0, total_drop: 0 }

    const calculatePrice = (price, qty, total_price_drop = 0) => ({
      totalPrice: price * qty,
      total_price_drop: total_price_drop * qty
    })

    if (Array.isArray(this.products)) {
      this.products.forEach(item => {
        console.log('updateProductPrice', item)
        let { totalPrice, total_price_drop } = { totalPrice: 0, total_price_drop: 0 }
        const qty = Number(item.quantity)

        const processcharacteristics = (characteristic) => {
          const price = characteristic.price
          const drop = characteristic.price_drop || characteristic.price

          console.log('updateProductPrice 3', price, drop, characteristic);
          return calculatePrice(price, qty, drop)
        }

        if (!item.is_order_edit && (item.characteristics || []).length > 0) {
          const characteristic = item.characteristics.find(c => c.id === item.characteristic_id)
          if (characteristic) {
            ({ totalPrice, total_price_drop } = processcharacteristics(characteristic))
          }
        } else {
          if (item.price_dropshipper > 0 || item.client_price > 0 || item.price_drop > 0) {
            const price = item.price
            const drop = item.price_drop || item.price;
            ({ totalPrice, total_price_drop } = calculatePrice(price, qty, drop))
          } else {
            totalPrice = item.price * qty
            total_price_drop = item.price * qty
          }
        }

        console.log('updateProductPrice 2', totalPrice, total_price_drop);

        // Update the item's prices
        if ((!totalPrice || !total_price_drop) && this.edit && !item.new) {
          totalPrice = item.total_price
          total_price_drop = item.total_price_drop
        } else {
          item.total_price = totalPrice
          item.total_price_drop = total_price_drop
        }

        // Reset characteristic_id if no characteristics
        if (!(item.characteristics || []).length) {
          item.characteristic_id = ''
        }

        // Accumulate totals
        prices.total += totalPrice
        prices.total_drop += total_price_drop
      })

      prices.total_drop = prices.total_drop ? prices.total_drop : 0
    }
    return prices
  }

  setDeliveryCost(data) {
    this.summary.delivery_cost = data.deliveryCost || 0
    this.deliverySummary.delivery_cost = data.deliveryCost || 0
    this.deliverySummary.delivery_data = data.deliveryDate || 0
  }

  saveOrder() {
    return this.orderSummary()
  }

  /**
   * Get order data object
   * @return object
   */
  getOrderData() {
    return {
      id: this.id,
      orderNum: this.orderNum,
      client: this.client,
      delivery: this.delivery,
      products: this.products,
      comment: this.comment,
      deliverySummary: this.deliverySummary,
      summary: this.summary,
      created_at: this.created_at
    }
  }

  orderSummary() {
    let products = null
    if (this.products) {
      products = this.products.map(p => {
        const result =
          /* (p.characteristics && p.characteristics.data) ? p.characteristics.data : */ p.characteristics
        if (result) {
          const characteristic = result.find(c => c.id === p.characteristic_id)
          if (typeof characteristic !== 'undefined') {
            return {
              id: p.product_id,
              total_price: p.total_price,
              total_price_drop: p.total_price_drop,
              quantity: p.quantity,
              characteristicId: p.characteristic_id,
              status: p.status,
              characteristic: {
                id: characteristic.id,
                price: characteristic.price,
                price_drop: characteristic.price_drop,
                price_dropshipper: characteristic.price_dropshipper
              },
              stages: p.stages && p.stages.map(s => {
                return {
                  id: s.id,
                  stage_id: s.stage_id,
                  photo: s.photo,
                  photoLayout:
                    typeof s.photoLayout !== 'undefined' ? s.photoLayout : null,
                  photo_layout:
                    typeof s.photo_layout !== 'undefined'
                      ? s.photo_layout
                      : null,
                  status: typeof s.status !== 'undefined' ? s.status : 'Новий',
                  ready: typeof s.ready !== 'undefined' ? s.ready : 0
                }
              })
            }
          } else {
            return {
              id: p.product_id,
              total_price: p.total_price,
              total_price_drop: p.total_price_drop,
              quantity: p.quantity,
              status: p.status
            }
          }
        }
        return null
      })
    }

    // console.log({
    //   id: this.id,
    //   client: this.client,
    //   delivery: this.delivery,
    //   products: products,
    //   comment: this.comment,
    //   deliverySummary: this.deliverySummary,
    //   deposit: this.deposit,
    //   summary: this.summary
    // });

    const data = JSON.stringify({
      id: this.id,
      client: this.client,
      delivery: this.delivery,
      products: products,
      comment: this.comment,
      deliverySummary: this.deliverySummary,
      deposit: this.deposit,
      summary: this.summary
    })
    // console.log(this.client)
    return { order: Base64.encode(data), data: data }
    // return data;
  }

  updateOrder(updateData) {
    Object.keys(updateData).forEach(key => {
      if (key in this) {
        if (typeof updateData[key] === 'object' && updateData[key] !== null && !(updateData[key] instanceof Array)) {
          this[key] = { ...this[key], ...updateData[key] }
        } else {
          this[key] = updateData[key]
        }
      }
    })
  }
}
export default Order
